<template>
  <div class="page vip-page">
    <HeadBar class="headbar" title=""></HeadBar>
    <div class="openvip">
      <div class="openvip-title">开通账号：{{ userInfo.username }}</div>
      <div class="left">
        
        <div class="openvip-detail">
          <div
            class="price"
          >
            <div
              class="price-item"
              :class="curSelected.id === packageItem.id && 'selected'"
              v-for="(packageItem, index) in packageList"
              :key="index"
              @click="handleChangePackage(packageItem)"
            >
              <span>{{ packageItem.title }}</span>
              <span>￥{{ packageItem.fee / 100 }}</span>
            </div>
            </div>
                      <div class="openvip-qrcode" >
              <div class="openvip-qrcode-detail">
                <div class="amount">支付金额: <span>{{ curSelected.fee / 100 }}</span> 元</div>
                <div class="expired" v-if="demoVipEndtime">会员有效期至: {{ demoVipEndtime }} </div>
                <img :src="curPayQrCode">
              </div>
            </div>
        </div>
      </div>
      
      <div class="vip-bottom-hint">
        <OpenvipHint />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import { getVipQR, getPayPkg } from '@/service/vip'
import QRCode from 'qrcode'
import OpenvipHint from '@/components/openvip-hint/index.vue';
import { Dialog } from 'vant'
import Toast from '@/utils/toast'
import { format } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import { sendLog } from '@/directives/v-log/log'

const DAY_TIME = 24 * 60 * 60 * 1000

export default {
  name: 'Vip',
  components: {
    OpenvipHint,
  },
  setup() {
    const store = useStore()

    let payQrUrl = ref('')
    let curPayQrCode = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')
    let curSelected = ref({})
    let packageList = ref([])

    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const macAddress = computed(() => store.state.macAddress)

    const demoVipEndtime = computed(() => {
      if (curSelected.value.days) {
        if (vipInfo.value.end_time) {
          // 累加
          return format(new Date(vipInfo.value.end_time).getTime() + curSelected.value.days * DAY_TIME, 'yyyy.MM.dd')
        }
        return format(Date.now() + curSelected.value.days * DAY_TIME, 'yyyy.MM.dd')
      }
      return ''
    })

    const handleVipInfoChange = (cur, prev) => {
      if (cur.value.end_time != prev.value.end_time) {
        Dialog.confirm({
          title: '开通成功',
          confirmButtonText: '知道了',
          showCancelButton: false,
          message:
            `恭喜您，成功开通VIP\n会员有效期：${cur.value.end_time.split(' ')[0]}`
        })
      }
    }

    const handleChangePackage = async (packageItem) => {
      store.dispatch('getCarplayInfo')
      if (!userInfo.value.unionid) {
        // this.setLoginModalEnabled(true)
        return
      }
      curSelected.value = packageItem
      curPayQrCode.value = 'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
      const { qr, order_id } = await getVipQR({
        mac_id: macAddress.value,
        pkg_id: packageItem.id,
        unionid: userInfo.value.unionid,
        openid: userInfo.value.openid
      })
      curPayQrCode.value = await QRCode.toDataURL(qr, { errorCorrectionLevel: 'H', margin: 0 })
      sendLog({
        event_type: 'show',
        event_name: 203,
      })
      if (order_id) {
        store.dispatch('startCheckPayStatus', {
          order_id,
        })
      } else {
        Toast('数据加载失败，请检查网络后重试')
      }
    }

    watch(() => cloneDeep(vipInfo), handleVipInfoChange, {
      deep: true
    })

    const handleGetPayPkg = async () => {
      packageList.value = await getPayPkg()
      if (packageList.value.length) {
        handleChangePackage(packageList.value[0])
      }
    }

    onBeforeMount(handleGetPayPkg)
    
    return {
      userInfo,
      vipInfo,
      payQrUrl,
      curPayQrCode,
      curSelected,
      packageList,
      demoVipEndtime,
      handleChangePackage,
    }
  }
}
</script>

<style lang="stylus" scoped>
.vip-page
  color #ffffff
.openvip
  // padding 10px 0 0 0
  font-size 32px
  .left
    display: flex
    align-items center
    flex-direction column
  &-qrcode
    display flex
    justify-content center
    flex 1
    &-detail
      position relative
      width 400px
      height 590px
      background url(https://qncweb.ktvsky.com/20211105/vadd/d56040cdccbd8565c2b8f6d94722a62f.png) no-repeat
      background-size 100% 100%
      color rgba(0, 0, 0, .8)
      font-size 34px
      text-align center
      .amount
        margin-top 77px
        span
          margin-left 10px
      .expired
        margin-top 10px
        font-size 27px
      img
        position absolute
        width 300px
        height 300px
        top 202px
        left 51px
  &-title
    color rgba(255, 255, 255, 0.8)
    margin-bottom 26.67px
    padding-left 220px
    @media screen and (max-width: 1200px)
      padding-left 6px
      padding-top 50px
  &-detail
    display flex
    margin-bottom 40px
    align-items center
    @media screen and (max-width: 1200px)
      flex-direction column-reverse
    .price
      height 584px
      margin-right 260px
      overflow-y scroll
      &::-webkit-scrollbar
        display: none
      @media screen and (max-width: 1200px)
        height 680px
        margin-right 0
        margin-top 50px
      &-item
        display flex
        padding 0 50px
        box-sizing border-box
        color #F0D290
        width 660px
        height 140px
        border-radius 10px
        justify-content space-between
        align-items center
        margin-bottom 40px
        background linear-gradient(90deg, #4C4435 0%, #37322F 100%)
        &.selected
          background linear-gradient(90deg, #F8ECD1 0%, #FFE6AF 100%)
          color rgba(0, 0, 0, .8)
.vip-bottom-hint
  padding-left 220px
  @media screen and (max-width: 1200px)
    padding-left 190px
</style>
