<template>
  <div class="openvip-qa" @click="handleClickQA">
    <img src="https://qncweb.ktvsky.com/20220210/vadd/72594bd33d5b719da25efc82b44e0384.png" alt="">
    <!-- <img src="https://qncweb.ktvsky.com/20211105/vadd/649e56d54b88616ee7392e370069ac94.png" alt=""> -->
    <span>支付后遇到问题，点击左侧刷新按钮</span>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { useStore } from 'vuex'

export default {
  name: 'OpenvipHint',

  setup() {
    const store = useStore()

    const handleClickQA = () => {
      Dialog.confirm({
        title: '会员异常',
        cancelButtonText: '取消',
        confirmButtonText: '重新获取',
        message: '请点击“重新获取”刷新VIP',
      }).then(() => {
        store.dispatch('getCarplayInfo')
      })
    }

    return {
      handleClickQA,
    }
  }

}
</script>

<style lang="stylus" scoped>
.openvip-qa
  width fit-content
  display flex
  align-items center
  color rgba(255, 255, 255, 0.5)
  font-size 28px
  img
    width 36px
    height 36px
    margin-right 20px
  span
    line-height 34px
    border-bottom 1px solid rgba(255, 255, 255, 0.5)
</style>
